import { createRouter, createWebHistory } from 'vue-router'
import { onAuthStateChanged } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import guardedRoutes from '@/router/guarded-routes'
import nonGuardedRoutes from '@/router/non-guarded-routes'
import DeviceDetective from '@/utils/DeviceDetective'
import { getFirebaseAuth } from '@/firebase'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...guardedRoutes, ...nonGuardedRoutes]
})

router.beforeEach((to, from, next) => {
  getFirebaseAuth().then(auth => {
    onAuthStateChanged(auth, user => {
      if (to.meta !== undefined && to.meta.requiredAuth) { // 行き先はログインが必須なページ
        if (user) { // ログイン中
          if (to.meta !== undefined && to.meta.superUserAuthority) { // super user 限定
            user.getIdTokenResult().then(result => {
              const claims = result.claims
              if (claims.super_user) { // super user なら通す
                next()
              } else {
                next({ name: 'dashboard' })
              }
            })
          } else { // ログインしていれば誰でもアクセス可能
          // BEGIN: ルートの例外
          // -------------------------------------------------------------------
            if (to.name === 'app.login') { // ログインユーザーがログイン画面に行こうとした場合はダッシュボードへ
              next({ name: 'dashboard' })
            }

            if (to.name === 'dashboard' && DeviceDetective.isMobile()) { // SPからダッシュボードにアクセス→業務選択へ
              next({ name: 'operationSelect' })
            }

            if (to.name === 'package.finder' && !DeviceDetective.isMobile()) { // PC からファインダーにアクセス→ダッシュボードへ
              next({ name: 'dashboard' })
            }
            // END: ルートの例外
            // -------------------------------------------------------------------

            next()
          }
        } else { // ログインなし
          next({ name: 'app.login' })
        }
      } else { // 行き先はログイン不要なページ
        next()
      }
    })
  })
})

router.afterEach((to) => {
  const analytics = getAnalytics()
  logEvent(analytics, 'page_view', {
    page_path: to.path
  })
})

export default router
