import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta'
import DeviceDetective from '@/utils/DeviceDetective'

import 'sanitize.css'

// // todo アイコンが限られているなら全読み出しじゃないほうがよいかも。
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

// @ts-expect-error
import VueQuagga from 'vue-quaggajs'

interface GlobalDataInterface {
  device: string;
}

const app = createApp({
  data (): GlobalDataInterface {
    return {
      device: DeviceDetective.isMobile() ? 'mobile' : 'pc'
    }
  },
  ...App
})

app.use(store)
app.use(router)

app.use(createMetaManager())
app.use(vueMetaPlugin)
app.use(VueQuagga)

app.mount('#app')
