import { RouteRecordRaw } from 'vue-router'
import DeviceDetective from '@/utils/DeviceDetective'

// 認証必要なルーティングの定義
const guardedRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'app.root',
    component: () => import('@/components/AppRoot.vue')
  },
  {
    path: '/logout',
    name: 'app.logout',
    component: () => import('@/views/Logout.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/operation-select',
    name: 'operationSelect',
    component: () => import('@/views/sp/OperationSelect.vue')
  },
  {
    path: '/package/finder',
    name: 'package.finder',
    component: () => import('@/views/sp/PackageFinder.vue')
  },
  {
    path: '/df',
    name: 'delivery-flow',
    component: () => import('@/views/sp/DeliveryFlow/Layout.vue'),
    children: [
      {
        path: 'info',
        name: 'df.info',
        component: () => import('@/views/sp/DeliveryFlow/Info.vue')
      },
      {
        path: 'arrival',
        name: 'df.arrival',
        component: () => import('@/views/sp/DeliveryFlow/Arrival.vue')
      },
      {
        path: 'verification/:id',
        name: 'df.verification',
        component: () => import('@/views/sp/DeliveryFlow/PackageVerification.vue')
      },
      {
        path: 'operation-guide',
        name: 'df.operationGuide',
        component: () => import('@/views/sp/DeliveryFlow/OperationGuide.vue')
      },
      {
        path: 'upload-evidence',
        name: 'df.captureConfirmation',
        component: () => import('@/views/sp/DeliveryFlow/UploadEvidence.vue')
      }
    ]
  },
  {
    path: '/package/:id',
    name: 'package.detail',
    component: () => {
      const componentPC = import('@/views/PackageDetail.vue')
      const componentSP = import('@/views/sp/PackageDetail.vue')

      return (DeviceDetective.isMobile()) ? componentSP : componentPC
    }
  },
  {
    path: '/package/export/:id/plan',
    name: 'package.export.pdf.plan',
    component: () => import('@/views/ExportPDFTemplate/ExportShippingPlan.vue')
  },
  {
    path: '/package/export/:id/logs',
    name: 'package.export.pdf.logs',
    component: () => import('@/views/ExportPDFTemplate/ExportBeaconLogs.vue')
  },
  // admin/ というページは今のところ無いため、テナント管理ページにリダイレクト
  {
    path: '/admin',
    redirect: {
      name: 'admin.management.tenant'
    }
  },
  {
    path: '/admin/company',
    name: 'admin.management.tenant',
    component: () => import('@/views/admin/ManagementTenant.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/group',
    name: 'admin.management.group',
    component: () => import('@/views/admin/ManagementGroup.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/user',
    name: 'admin.management.user',
    component: () => import('@/views/admin/ManagementUser.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/dashboard',
    name: 'admin.management.dashboard',
    component: () => import('@/views/admin/ManagementDashboard.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/dashboard-archive',
    name: 'admin.management.dashboard-archive',
    component: () => import('@/views/admin/ManagementDashboard.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/package/:id',
    name: 'admin.management.package.detail',
    component: () => import('@/views/admin/ManagementPackageDetail.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/package-archive/:id',
    name: 'admin.management.package-archive.detail',
    component: () => import('@/views/admin/ManagementPackageDetail.vue'),
    meta: {
      superUserAuthority: true
    }
  },
  {
    path: '/admin/company/:companyId/package/export/:id/plan',
    name: 'admin.management.package.export.pdf.plan',
    component: () => import('@/views/ManagementExportPDFTemplate/ExportShippingPlan.vue')
  },
  {
    path: '/admin/company/:companyId/package/export/:id/logs',
    name: 'admin.management.package.export.pdf.logs',
    component: () => import('@/views/ManagementExportPDFTemplate/ExportBeaconLogs.vue')
  },
  {
    path: '/admin/company/:companyId/package-archive/export/:id/logs',
    name: 'admin.management.package-archive.export.pdf.logs',
    component: () => import('@/views/ManagementExportPDFTemplate/ExportBeaconLogs.vue')
  },
  // 出荷前検査
  {
    path: '/pre-shipment/dashboard',
    name: 'PreShipmentDashboard',
    component: () => import('@/views/PreShipment/PreShipmentDashboard.vue')
  },
  {
    path: '/pre-shipment/inspect',
    name: 'PreShipmentInspect',
    component: () => import('@/views/PreShipment/PreShipmentInspect.vue')
  }
]
// ここまで

// 要認証フラグを付ける
guardedRoutes.forEach((routeObject, index) => {
  if (!guardedRoutes[index].meta) {
    // meta を持たないルートの場合は meta ごと作成
    Object.assign(guardedRoutes[index], {
      meta: {
        requiredAuth: true
      }
    })
  } else {
    // meta がある場合は追加
    guardedRoutes[index].meta = Object.assign(
      guardedRoutes[index].meta,
      {
        requiredAuth: true
      }
    )
  }
})

export default guardedRoutes
