import { RouteRecordRaw } from 'vue-router'

// 認証不要なルートの定義
const nonGuardedRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'app.login',
    component: () => import('@/views/Login.vue')
  }
]
// ここまで

export default nonGuardedRoutes
