import isMobile from 'ismobilejs'
const m = isMobile()

export default {
  isAny: (): boolean => m.any,
  isMobile: (): boolean => m.phone,
  isTablet: (): boolean => m.tablet
  // isApple: () => m.apple, 未使用のようなのでコメントアウト。
  // isAndroid: () => m.android 未使用のようなのでコメントアウト。
}
