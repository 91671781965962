import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfigUrl = process.env.VUE_APP_FIREBASE_URL + '/__/firebase/init.json'

export const getFirebaseAuth = async () => {
  const firebaseConfigResponse = await fetch(firebaseConfigUrl)
  const firebaseConfig = await firebaseConfigResponse.json()
  const app = initializeApp(firebaseConfig)
  return getAuth(app)
}
