import { createStore } from 'vuex'
import {
  CompanyInterface,
  LoginUserInterface,
  GetShippingPlanArgsInterface
} from '@/@types/component-interfaces/FirebaseArgsInterface'
import { ShippingPlanInterface } from '@/@types/component-interfaces/shipping-plan-interface'
import { QueryDocumentSnapshot } from 'firebase/firestore'

interface StateInterface {
  user: LoginUserInterface;
  company: CompanyInterface;
  problematicItems: {
    items: Array<ShippingPlanInterface>;
    allLogsLoaded: boolean;
    startAfter: QueryDocumentSnapshot|null
  };
  paginateControlCache: {
    isLastPage: boolean,
    currentPage: number,
    perPage: number
  }
  dashboardItemListCache: ShippingPlanInterface[];
  queryArgsCache: GetShippingPlanArgsInterface;
  AdminPaginateControlCache: {
    isLastPage: boolean,
    currentPage: number,
    perPage: number
  }
  AdminDashboardItemListCache: ShippingPlanInterface[];
  AdminQueryArgsCache: GetShippingPlanArgsInterface;
  AdminSelectedCompanyId: string;
}

const getDefaultState = (): StateInterface => {
  return {
    user: {
      displayName: '',
      email: '',
      superUser: false
    },
    company: {},
    problematicItems: {
      items: [],
      allLogsLoaded: false,
      startAfter: null
    },
    paginateControlCache: {
      isLastPage: false,
      currentPage: 1,
      perPage: 10
    },
    dashboardItemListCache: [],
    queryArgsCache: {
      date: {
        start: '',
        end: ''
      },
      paginateCursor: {}
    },
    AdminPaginateControlCache: {
      isLastPage: false,
      currentPage: 1,
      perPage: 10
    },
    AdminDashboardItemListCache: [],
    AdminQueryArgsCache: {
      date: {
        start: '',
        end: ''
      },
      paginateCursor: {}
    },
    AdminSelectedCompanyId: ''
  }
}

export default createStore({
  state: getDefaultState(),
  mutations: {
    mutateLoginUser (state: StateInterface, payload: LoginUserInterface) {
      state.user = payload
    },
    mutateCompany (state: StateInterface, payload: CompanyInterface) {
      state.company = payload
    },
    // 異常検知データの登録
    mutateProblematicItems (state, payload) {
      state.problematicItems = payload
    },
    mutatePaginateControlCache (state, payload) {
      state.paginateControlCache = payload
    },
    mutateDashboardItemListCache (state, payload) {
      state.dashboardItemListCache = payload
    },
    mutateQueryArgsCache (state, payload) {
      state.queryArgsCache = payload
    },
    resetPaginateStates (state) {
      // ページ送り関係のストアをリセット
      Object.assign(state.paginateControlCache, getDefaultState().paginateControlCache)
      Object.assign(state.dashboardItemListCache, getDefaultState().dashboardItemListCache)
    },
    resetAllStates (state) {
      // すべてのストアをリセット
      Object.assign(state, getDefaultState())
    },
    mutateAdminPaginateControlCache (state, payload) {
      state.AdminPaginateControlCache = payload
    },
    mutateAdminDashboardItemListCache (state, payload) {
      state.AdminDashboardItemListCache = payload
    },
    mutateAdminQueryArgsCache (state, payload) {
      state.AdminQueryArgsCache = payload
    },
    resetAdminPaginateStates (state) {
      // ページ送り関係のストアをリセット
      Object.assign(state.AdminPaginateControlCache, getDefaultState().AdminPaginateControlCache)
      state.AdminDashboardItemListCache = Object.assign(getDefaultState().AdminDashboardItemListCache)
    },
    mutateSelectedCompanyId (state, payload) {
      state.AdminSelectedCompanyId = payload
    }
  },
  actions: {
    commitLoginUser ({ commit }, payload: LoginUserInterface) {
      commit('mutateLoginUser', payload)
    },
    commitCompany ({ commit }, payload: CompanyInterface) {
      commit('mutateCompany', payload)
    },
    commitProblematicItems ({ commit }, payload) {
      commit('mutateProblematicItems', payload)
    },
    commitAdditionalProblematicItems ({ commit }, payload) {
      commit('mutateAdditionalProblematicItems', payload)
    },
    commitPaginateControlCache ({ commit }, payload) {
      commit('mutatePaginateControlCache', payload)
    },
    commitDashboardItemListCache ({ commit }, payload) {
      commit('mutateDashboardItemListCache', payload)
    },
    commitQueryArgsCache ({ commit }, payload) {
      commit('mutateQueryArgsCache', payload)
    },
    commitResetPaginateStates ({ commit }) {
      commit('resetPaginateStates')
    },
    commitResetAllStates ({ commit }) {
      commit('resetAllStates')
    },
    commitAdminPaginateControlCache ({ commit }, payload) {
      commit('mutateAdminPaginateControlCache', payload)
    },
    commitAdminDashboardItemListCache ({ commit }, payload) {
      commit('mutateAdminDashboardItemListCache', payload)
    },
    commitAdminQueryArgsCache ({ commit }, payload) {
      commit('mutateAdminQueryArgsCache', payload)
    },
    commitAdminResetPaginateStates ({ commit }) {
      commit('resetAdminPaginateStates')
    },
    commitAdminSelectedCompanyId ({ commit }, payload) {
      commit('mutateSelectedCompanyId', payload)
    }
  },
  modules: {
  }
})
